import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyAuTi728ZsMZLuxiQWUnyGbQNrvJ29YUWI',
  authDomain: 'codex-ifaguaviva.firebaseapp.com',
  projectId: 'codex-ifaguaviva',
  storageBucket: 'codex-ifaguaviva.appspot.com',
  messagingSenderId: '1089250178754',
  appId: '1:1089250178754:web:080803aedc375920912d5b',
  measurementId: 'G-ZDS6KPH83K',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
